import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageTracker() {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = (path) => {
      window.gtag('event', 'page_view', {
        page_path: path,
      });
    };

    trackPageView(location.pathname);
  }, [location]);
}
