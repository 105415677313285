import React, { useState, useEffect } from 'react';
import Airtable from "airtable";
import './Waitlist.css';
import { usePageTracker } from './usePageViews'; 

const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_ID = process.env.REACT_APP_BASE_ID;
const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;

const base = new Airtable({ apiKey: API_KEY }).base(BASE_ID);

function Waitlist() {
  usePageTracker();
  
  const [name, setName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [network, setNetwork] = useState('');
  const [twitter, setTwitter] = useState('');
  const [discord, setDiscord] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await base(TABLE_NAME).create([
        { 
          fields: { 
            'Name': name, 
            'Name of the Project': projectName,
            'Network': network,
            'Twitter': twitter,
            'Discord': discord,
            'Email': email 
          } 
        },
      ]);
      setName("");
      setProjectName("");
      setNetwork("");
      setTwitter("");
      setDiscord("");
      setEmail("");
      setSuccess(true)
    } catch (error) {
      console.error(error);
      alert("Oops, there was an error. Please try again.");
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.querySelectorAll(".animated-element").forEach((el) => {
      if (el.getBoundingClientRect().top < window.innerHeight) {
        el.classList.add("in-view");
      } else {
        el.classList.remove("in-view");
      }
    });
  }, [scrollPosition]);

  return (
    <div className="waitlist-container">
      <div className="left-section">
        <form onSubmit={handleSubmit}>
          <label for="name">Your Name</label>
          <input id="name" type="text" value={name} onChange={event => setName(event.target.value)} required />

          <label for="projectName">Name of the Project</label>
          <input id="projectName" type="text" value={projectName} onChange={event => setProjectName(event.target.value)} required />

          <label for="network">Network</label>
          <input id="network" type="text" value={network} onChange={event => setNetwork(event.target.value)} required />

          <label for="twitter">Twitter of the Project</label>
          <input id="twitter" type="text" value={twitter} onChange={event => setTwitter(event.target.value)} required />

          <label for="discord">Website</label>
          <input id="discord" type="text" value={discord} onChange={event => setDiscord(event.target.value)} required />

          <label for="email">Email</label>
          <input id="email" type="email" value={email} onChange={event => setEmail(event.target.value)} required />

          {success ? <h2 style={{marginTop:'0px', fontWeight:'600'}}>Done! Now wait for more Alpha</h2> : <button type="submit">Let's go!</button>}
        </form>
      </div>
      <div className="right-section">
        <h1 className='animated-element'>Do you really wanna join our Waitlist?</h1>
        <h2 className='animated-element'>A lot of Web3 businesses are waiting for you!</h2>
      </div>
    </div>
  );
}

export default Waitlist;
