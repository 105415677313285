import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // <-- add this line
import Waitlist from './Waitlist';
import Homepage from './Homepage';

function App() {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PartnerChain - Web3 Partnerships</title>
        <link rel="canonical" href="http://partnerchain.xyz/" />
        <meta property="og:title" content="PartnerChain" />
        <meta property="og:description" content="Web3 Partnerships" />
        <meta property="og:image" content="https://www.partnerchain.xyz/metadata.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/waitlist" element={<Waitlist />} />
      </Routes>
    </Router>
  );
}

export default App;
