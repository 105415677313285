import React from 'react';
import './App.css';
import logo from './assets/logo.svg';
import twitterIcon from './assets/x.png';
import linkedInIcon from './assets/linkedin.png';
import logo2 from './assets/logo2.svg'

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={logo2} alt="logo-footer" className="logo-footer" />
      </div>
      <div className="footer-center">
        &copy; {new Date().getFullYear()} PartnerChain
      </div>
      <div className="footer-right">
        <a href="https://twitter.com/partnerchain" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter" className="x-icon" />
        </a>
        <a href="https://www.linkedin.com/company/partnerchainweb3" target="_blank" rel="noopener noreferrer">
          <img src={linkedInIcon} alt="LinkedIn" className="linkedin-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
