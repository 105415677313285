import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import Airtable from "airtable";
import './App.css';
import logo from './assets/logo.svg'
import { useNavigate, useLocation } from 'react-router-dom';  
import ReactGA from 'react-ga';
import { usePageTracker } from './usePageViews';  // Import the custom hook
import Footer from './Footer';

function Homepage() {

  const navigate = useNavigate();

  const redirectToWaitlist = () => {
    navigate('/waitlist');  // redirect to /waitlist
    window.gtag('event', 'click', {
      event_category: 'User',
      event_label: 'Clicked Find your Web3 soulmates',
    });
  };
  
  usePageTracker();  // Use the custom hook


  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.querySelectorAll(".animated-element").forEach((el) => {
      if (el.getBoundingClientRect().top < window.innerHeight) {
        el.classList.add("in-view");
      } else {
        el.classList.remove("in-view");
      }
    });
  }, [scrollPosition]);

  


    return (

      <div>
      <div className="container">
        <header className="header">
          <img src={logo} alt="logo" className="logo" />
        </header>
        <main>
          <h1 className="main-title">Find Strategic Partners <br></br>for your project</h1>
          <h2 className="sub-title animated-element">We empower businesses to make Web3 more collaborative</h2>
          <a href='https://beta.partnerchain.xyz'><button className="cta-button" >Try Our Beta</button></a>
        </main>
      </div>
      <div className="container-black">
        <h2 className="green-subtitle-left animated-element">Finding the right partners is time-consuming</h2>
        <h2 className="green-subtitle-right animated-element">Closing an agreement lacks transparency. </h2>
        <h2 className="green-subtitle-left animated-element">Building a reputation as a great partner too.</h2>
      </div>
      <div className="container2">
        <main className='main-section'>
          
            <h2 className="sub-title2 animated-element">Meet potential partners,<br></br>shake their hands.</h2>
            <h3 className="sub-title3 animated-element">1. Search and filter different businesses<br></br>2. Connect with them<br></br>3. Close a partnership deal<br></br>4. Build a reputation as a great partner</h3>
         
          
         
        </main>
        
      </div>
      <Footer/>
    </div>
    );
  }

export default Homepage;
